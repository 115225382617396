import { createSlice } from '@reduxjs/toolkit'

import { DocumentEntity } from '../../../../redux-store/models'
import { Payload, ReduxRootState } from '../../../../types'

interface State {
  main_document: DocumentEntity | null
  documents: DocumentEntity[]
}

const initialState: State = {
  main_document: null,
  documents: [],
}

const currentDocumentsSlice = createSlice({
  name: 'currentDocuments',
  initialState,
  reducers: {
    setMainDocument: (state, { payload }: Payload<DocumentEntity>) => {
      state.main_document = payload
    },
    setDocuments: (state, { payload }: Payload<DocumentEntity[]>) => {
      state.documents = payload
    },
    removeMainDocument: (state) => {
      state.main_document = null
    },
    removeDocument: (state, { payload }: Payload<string>) => {
      state.documents = state.documents.filter((d) => d.id !== payload)
    },
  },
})

export const {
  setMainDocument,
  setDocuments,
  removeMainDocument,
  removeDocument,
} = currentDocumentsSlice.actions

export const currentDocumentsReducer = currentDocumentsSlice.reducer

export const currentDocumentsSelector = {
  selectMainDocument: (state: ReduxRootState) =>
    state.currentDocuments.main_document,
  selectCurrentDocuments: (state: ReduxRootState) =>
    state.currentDocuments.documents,
}
