import MasterIcon from 'assets/Icons/MasterIcon'
import { Card, Typography } from 'components/common'
import DocumentMorePopover from 'components/features/Documents/components/DocumentMorePopover/DocumentMorePopover'
import DocRevisionEnum from 'components/features/Documents/enums/DocRevision'
import { useAppDispatch, useFrontOfficeCheck } from 'hooks'
import { t } from 'i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { Metadata } from 'types'

import styles from './MainDocumentCard.module.scss'
import {
  OfficeName,
  PracticeQueryParams,
} from '../../../../../constants/application-constants'
import { removeMainDocument } from '../../../Documents/api/currentDocumentsSlice'

interface MainDocumentCardProps {
  document: Metadata
  docId?: string
}

const MainDocumentCard = ({
  document,
  docId,
}: MainDocumentCardProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const isFrontOffice = useFrontOfficeCheck()

  const revisionStatus = searchParams.get(PracticeQueryParams.REVISION_STATUS)
  const querySearch = `rs=${
    revisionStatus ? revisionStatus : DocRevisionEnum.published
  }`

  const onDocumentDelete = () => {
    dispatch(removeMainDocument())
  }

  return (
    <Card className={styles.root}>
      <Card.Body>
        <div className={styles.wrapper}>
          <div className={styles.mainIcon}>
            <MasterIcon />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.text}>
              <Link
                to={{
                  pathname: `/${
                    isFrontOffice ? OfficeName.FRONT : OfficeName.BACK
                  }/documents/${docId}`,
                  search: querySearch,
                }}
                className={styles.link}
                aria-describedby="mainTitle"
              >
                <Typography color="label" id="mainTitle" fontWeight="medium">
                  {document.title}
                </Typography>
              </Link>

              <Typography color="label" size="small" className={styles.subject}>
                {document.subject}
              </Typography>
            </div>

            <span className={styles.fakeLink} aria-hidden="true">
              {t('practice.mainLink')}
            </span>
          </div>

          {docId && (
            <DocumentMorePopover
              documentId={docId}
              className={styles.clickable}
              onDocumentDelete={onDocumentDelete}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

export default MainDocumentCard
