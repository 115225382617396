import { Modal } from 'components/common'

import styles from './UserOnboardingPage.module.scss'
import { UserOnboarding } from '../../components'

const UserOnboardingPage = ({
  showCloseButton,
}: {
  showCloseButton: boolean
}): JSX.Element => {
  return (
    <Modal open noFocusTrap className={styles.modal}>
      <UserOnboarding showCloseButton={showCloseButton} />
    </Modal>
  )
}

export default UserOnboardingPage
