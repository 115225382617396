import { useParams } from 'react-router-dom'

import styles from './DocumentBackOffice.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import {
  currentDocumentsSelector,
  removeDocument,
  removeMainDocument,
} from '../../api/currentDocumentsSlice'
import DocumentMorePopover from '../DocumentMorePopover/DocumentMorePopover'
import FavoriteButton from '../FavoriteButton/FavoriteButton'

const DocumentBackOfficeHeader = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { documentId = '' } = useParams()
  const currentMainDocument = useAppSelector(
    currentDocumentsSelector.selectMainDocument
  )
  const currentDocuments = useAppSelector(
    currentDocumentsSelector.selectCurrentDocuments
  )

  const handleDocumentDelete = () => {
    if (currentMainDocument && currentMainDocument.id === documentId) {
      dispatch(removeMainDocument())
    }

    if (
      currentDocuments?.length > 0 &&
      currentDocuments.find((d) => d.id === documentId)
    ) {
      dispatch(removeDocument(documentId))
    }
  }

  return (
    <header className={styles.root}>
      <DocumentMorePopover
        className={styles.moreBtn}
        documentId={documentId}
        styleInverted={true}
        onDocumentDelete={handleDocumentDelete}
      />
      <FavoriteButton className={styles.favBtn} styleInverted={true} />
    </header>
  )
}

export default DocumentBackOfficeHeader
