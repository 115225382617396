import React, { useCallback, useState } from 'react'

import { addToast } from 'components/features/Other/api'
import { useAppDispatch } from 'hooks'
import { useNavigate } from 'react-router-dom'

import { OnboardingStep } from './UserOnboarding.types'
import UserOnboardingInformation from './UserOnboardingInformation'
import UserOnboardingInterests from './UserOnboardingInterests'
import { userApi } from '../../api'

const UserOnboarding = ({
  showCloseButton,
}: {
  showCloseButton: boolean
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const [location, setLocation] = useState('')
  const [department, setDepartment] = useState('')
  const [step, setStep] = useState<OnboardingStep>('information')
  const [selectedTopics, setSelectedTopics] = useState<string[]>([])
  const [httpOnboardUser, httpOnboardUserState] =
    userApi.useOnboardUserMutation()
  const [refetchCurrentUser] = userApi.useLazyGetCurrentUserQuery()

  const navigate = useNavigate()

  const handleInformationStep = useCallback(
    (info: { location: string; department: string }) => {
      setLocation(info.location)
      setDepartment(info.department)

      setStep('interests')
    },
    []
  )
  const handleCompleteProfile = async () => {
    const isDataInvalid = selectedTopics.length <= 0 || !department || !location

    if (isDataInvalid) {
      dispatch(
        addToast({
          color: 'error',
          content: 'Data is missing',
        })
      )
      return
    }

    try {
      await httpOnboardUser({
        departmentIds: [department],
        locationID: location,
        topics: selectedTopics,
      })
      await refetchCurrentUser(null)
      navigate('/')
    } catch (error) {
      dispatch(
        addToast({
          color: 'error',
          content: 'Something went wrong',
        })
      )
    }
  }

  return (
    <>
      {step === 'information' && (
        <UserOnboardingInformation
          handleInformationStep={handleInformationStep}
          showCloseButton={showCloseButton}
        />
      )}
      {step === 'interests' && (
        <UserOnboardingInterests
          isSubmitting={httpOnboardUserState.isLoading}
          selectedTopics={selectedTopics}
          onBackClick={() => setStep('information')}
          onSubmit={handleCompleteProfile}
          onTopicSelect={setSelectedTopics}
          showCloseButton={showCloseButton}
        />
      )}
    </>
  )
}

export default UserOnboarding
