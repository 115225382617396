import { FormMetadataValues } from '../types'

const createEmptyMetadata = (
  override?: Partial<FormMetadataValues>
): FormMetadataValues => ({
  accessRights: null,
  altTopic: [],
  contact: null,
  countries: [],
  departments: [],
  effectiveAt: '',
  language: '',
  legalEntities: [],
  locations: [],
  longTitle: '',
  mainTopic: null,
  targetGroup: '',
  title: '',
  code: '',
  externalReferenceLinks: [],
  ...override,
})

export default createEmptyMetadata
