import { OfficeName, Permissions } from 'constants/application-constants'

import { MoreVert } from 'assets/Icons'
import { Button, Tooltip } from 'components/common'
import { usePermissions } from 'hooks'
import { t } from 'i18next'
import { Edit2 } from 'react-feather'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import styles from './DocumentFrontOfficeHeader.module.scss'
import { documentApi } from '../../api/documentsApi'
import DocumentDownloadUrl from '../DocumentDownloadUrl/DocumentDownloadUrl'
import FavoriteButton from '../FavoriteButton/FavoriteButton'

const DocumentFrontOfficeHeader = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const { documentId = '', pid } = useParams()
  const perms = usePermissions(pid).mixed
  const isBackOfficeUser = !!perms
    ? perms[Permissions.ACCESS_BACKOFFICE] ?? false
    : false

  const httpGetDoc =
    documentApi.endpoints.getDocumentById.useQueryState(documentId)
  const documentType = httpGetDoc.data?.data?.type ?? 'annex'

  const handleEditPracticeClick = () => {
    navigate({
      pathname: `/${OfficeName.BACK}/documents/${documentId}`,
      search: location.search,
    })
  }

  const openMoreDrawer = () => {
    navigate(
      {
        ...location,
        hash: '#drawer',
      },
      {
        replace: true,
      }
    )
  }

  const buttonLabel =
    documentType === 'template' || documentType === 'checklist'
      ? t('document.templateDownload')
      : t('document.popoverDownload')

  return (
    <div className={styles.root}>
      {!httpGetDoc.isFetching && (
        <DocumentDownloadUrl
          documentId={documentId}
          documentType={documentType}
          button={{ variant: 'secondary', label: buttonLabel }}
        />
      )}

      {isBackOfficeUser && (
        <Tooltip
          content={t('document.editBtnTooltip')}
          position="bottom"
          boxAlignment="end"
          color="dark"
          className={styles.editBtn}
        >
          <Button
            // disabled
            variant="secondary"
            size="x-small"
            icon={<Edit2 />}
            onClick={handleEditPracticeClick}
          >
            {t('frontOffice.actionEdit')}
          </Button>
        </Tooltip>
      )}

      <FavoriteButton className={styles.favBtn} />

      <Button
        size="x-small"
        variant="subtle"
        icon={<MoreVert />}
        onClick={openMoreDrawer}
        className={styles.sidebarBtn}
        aria-label={t('frontOffice.ariaSidebarBtn') ?? ''}
      />
    </div>
  )
}

export default DocumentFrontOfficeHeader
