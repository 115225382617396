import { useState } from 'react'

import {
  Button,
  Card,
  DropdownItem,
  IconContainer,
  LoadingIndicator,
  Modal,
  Typography,
} from 'components/common'
import { addToast } from 'components/features/Other/api'
import { useAppDispatch, useToggle } from 'hooks'
import { t } from 'i18next'
import { AlertCircle, Trash2 } from 'react-feather'

import styles from './DocumentDeleteModal.module.scss'
import { documentApi } from '../../api/documentsApi'

const DocumentDeleteModal = ({
  documentId,
  togglePopover,
  onDocumentDelete,
}: {
  documentId: string
  togglePopover: () => void
  onDocumentDelete: () => void
}): JSX.Element => {
  const [isModalOpen, toggleModal] = useToggle()
  const dispatch = useAppDispatch()
  const [isDeleting, setIsDeleting] = useState(false)

  const [deleteDocument] = documentApi.useDeleteDocumentMutation()

  const handleClose = () => {
    if (isDeleting) return false

    toggleModal(false)
    togglePopover()
  }

  const handleDeleteDocument = async () => {
    if (!documentId) return

    try {
      setIsDeleting(true)
      await deleteDocument({
        documentId: documentId,
      }).unwrap()

      dispatch(
        addToast({
          color: 'success',
          content: t('document.deleted'),
        })
      )

      onDocumentDelete()
    } catch (err) {
      dispatch(addToast({ color: 'error', content: t('error.general') }))
    } finally {
      setIsDeleting(false)
      handleClose()
    }
  }

  return (
    <>
      <Modal open={isModalOpen} handleClose={handleClose}>
        <Card className={styles.card}>
          <Card.Header>
            <IconContainer
              icon={<AlertCircle />}
              color={'error'}
              withBackground
            />
            <Typography
              element={'h2'}
              size={'x-large'}
              fontWeight={'bold'}
              className={styles.heading}
            >
              {t('document.deleteDocumentHeader')}
            </Typography>
            <Typography>
              {t('document.deleteDocumentDocumentWillBeRemoved')}
            </Typography>
            <Typography>{t('document.deleteDocumentNotUndone')}</Typography>
          </Card.Header>
          <Card.Footer className={styles.footer}>
            <Button
              size={'small'}
              variant="text"
              disabled={isDeleting}
              onClick={handleClose}
            >
              {t('document.cancelDelete')}
            </Button>
            <Button
              onClick={() => handleDeleteDocument()}
              disabled={isDeleting}
              size={'small'}
              icon={
                isDeleting ? <LoadingIndicator size="x-small" /> : <Trash2 />
              }
              color="error"
            >
              {t('document.confirmDelete')}
            </Button>
          </Card.Footer>
        </Card>
      </Modal>
      <DropdownItem
        iconColor={'error'}
        icon={<Trash2 />}
        onClick={() => toggleModal(true)}
      >
        <Typography color={'error'}>{t('document.delete')}</Typography>
      </DropdownItem>
    </>
  )
}

export default DocumentDeleteModal
