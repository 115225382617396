import { useRef, useState } from 'react'

import clsx from 'clsx'
import { Avatar, DropdownItem, QibriLogo, Typography } from 'components/common'
import { userApi } from 'components/features/User/api'
import { t } from 'i18next'
import { LogOut, MessageSquare, UserCheck } from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import { CLIENT_DATA } from 'temp/temp-constants'
import { getLogo } from 'temp/utils'

import styles from './DefaultHeader.module.scss'
import { BookOpen } from '../../../../../assets/Icons'
import { authTokenService } from '../../../../../helpers'
import { Popover } from '../../../Popover'

interface PageHeaderProps {
  className?: string
}

const FEEDBACK_URL =
  'https://forms.clickup.com/1560/f/1gr-8288/RO7MZOJH4UF0DSCEGZ'

const SYSTEM_GUIDE_URL =
  'https://nextworkx.sharepoint.com/:b:/s/xChange/EWerCCF2aGdMrOSiR50B618BhcjbMqQNzrM-VGXwUmP-QA?e=d4JP5U'

const DefaultHeader = ({ className }: PageHeaderProps): JSX.Element => {
  const classes = clsx(styles.root, className)
  const [open, setOpen] = useState(false)
  const btnRef = useRef<HTMLDivElement>(null)

  const { isSuccess, data } =
    userApi.endpoints.getCurrentUser.useQueryState(null)

  const clientId = data?.data.customer?.id || ''
  const isNotQibri = Object.values(CLIENT_DATA).includes(clientId)
  const companyLogo = getLogo(clientId, false)
  const renderedLogo = isNotQibri ? companyLogo : <QibriLogo />

  const [triggerLogout] = userApi.useLazyLogoutQuery()
  const navigate = useNavigate()

  const handleBtnClick = (evt: React.MouseEvent) => {
    evt.preventDefault()
    evt.stopPropagation()

    setOpen(true)
  }

  const handleDismiss = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    handleDismiss()
    authTokenService.clearTokens()

    triggerLogout(null, false).then(function () {
      window.location.reload()
    })
  }

  return (
    <header className={classes}>
      <Link to="/" className={styles.logo}>
        <span className="sr-only">Back to Home</span>
        {renderedLogo}
      </Link>

      <div className={styles.actions}>
        {isSuccess && (
          <div className={styles.popoverContainer}>
            <div className={styles.triggerContainer} ref={btnRef}>
              <Avatar
                content={data?.data.initials ?? ''}
                src={data?.data.avatar_public_path}
                onClick={handleBtnClick}
                className={styles.trigger}
              />
            </div>
            <Popover
              open={open}
              onClose={handleDismiss}
              anchorEl={btnRef.current}
              // id={id}
              // className={}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
            >
              <DropdownItem
                icon={<UserCheck />}
                onClick={() => {
                  handleDismiss()
                  navigate('/user/onboard', {
                    state: {
                      showCloseButton: true,
                      backgroundLocation: '/',
                    },
                  })
                }}
              >
                <Typography>{t('common.updateProfile')}</Typography>
              </DropdownItem>
              <DropdownItem
                icon={<MessageSquare />}
                onClick={() => window.open(FEEDBACK_URL, '_blank')}
              >
                <Typography>{t('common.feedback')}</Typography>
              </DropdownItem>
              <DropdownItem
                icon={<BookOpen />}
                onClick={() => window.open(SYSTEM_GUIDE_URL, '_blank')}
              >
                <Typography>{t('common.systemHandbook')}</Typography>
              </DropdownItem>
              <DropdownItem icon={<LogOut />} onClick={handleLogout}>
                <Typography>{t('common.logout')}</Typography>
              </DropdownItem>
            </Popover>
          </div>
        )}
      </div>
    </header>
  )
}

export default DefaultHeader
